import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@priva/components/button';
import { PrivaButtonModule } from '@priva/components/button';
import * as i3 from '@priva/components/title';
import { PrivaTitleModule } from '@priva/components/title';
import * as i4 from '@ngx-translate/core';
import { PrivaLocalizationModule } from '@priva/localization';
const _c0 = ["*"];
function PrivaExpanderComponent_Conditional_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PrivaExpanderComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵtemplate(1, PrivaExpanderComponent_Conditional_3_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.triggerTemplate);
  }
}
function PrivaExpanderComponent_Conditional_4_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.triggerText, " ");
  }
}
function PrivaExpanderComponent_Conditional_4_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "priva-title", 5);
    i0.ɵɵtemplate(1, PrivaExpanderComponent_Conditional_4_Conditional_0_Conditional_1_Template, 2, 1, "span", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.triggerSize)("title", !ctx_r0.hasSoftTrigger ? ctx_r0.triggerText : "")("theme", ctx_r0.theme);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.hasSoftTrigger ? 1 : -1);
  }
}
function PrivaExpanderComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PrivaExpanderComponent_Conditional_4_Conditional_0_Template, 2, 4, "priva-title", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(ctx_r0.triggerIconState.toLowerCase() !== "only" ? 0 : -1);
  }
}
var PrivaExpanderThemeClass;
(function (PrivaExpanderThemeClass) {
  PrivaExpanderThemeClass["onwhite"] = "expander-theme-on-white";
  PrivaExpanderThemeClass["onlightgray"] = "expander-theme-on-light-gray";
  PrivaExpanderThemeClass["onlightblue"] = "expander-theme-on-light-blue";
  PrivaExpanderThemeClass["ondarkblue"] = "expander-theme-on-dark-blue";
})(PrivaExpanderThemeClass || (PrivaExpanderThemeClass = {}));
class PrivaExpanderComponent {
  constructor() {
    this.theme = 'onwhite';
    this.triggerIconState = 'left';
    this.triggerSize = 'md';
    this.isExpanded = false;
    this.hasSoftTrigger = false;
    this.triggerClick = new EventEmitter();
    this.PrivaExpanderTheme = PrivaExpanderThemeClass;
    this.PrivaExpanderThemeClass = PrivaExpanderThemeClass;
  }
  static {
    this.ɵfac = function PrivaExpanderComponent_Factory(t) {
      return new (t || PrivaExpanderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaExpanderComponent,
      selectors: [["priva-expander"]],
      inputs: {
        theme: "theme",
        triggerIconState: "triggerIconState",
        triggerText: "triggerText",
        triggerSize: "triggerSize",
        triggerTemplate: "triggerTemplate",
        isExpanded: "isExpanded",
        hasSoftTrigger: "hasSoftTrigger"
      },
      outputs: {
        triggerClick: "triggerClick"
      },
      ngContentSelectors: _c0,
      decls: 7,
      vars: 11,
      consts: [[1, "expander", 3, "ngClass"], ["data-test", "expander-button", "priva-button", "", "type", "button", "importance", "none", "iconClass", "expander-trigger-icon far fa-chevron-right", 1, "expander-trigger", 3, "click", "theme", "iconState"], [1, "expander-trigger-content"], ["data-test", "expander-content", 1, "expander-content"], [4, "ngTemplateOutlet"], [3, "size", "title", "theme"], ["data-title", "", 1, "expander-trigger-label"]],
      template: function PrivaExpanderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "button", 1);
          i0.ɵɵpipe(2, "translate");
          i0.ɵɵlistener("click", function PrivaExpanderComponent_Template_button_click_1_listener($event) {
            return ctx.triggerClick.emit($event);
          });
          i0.ɵɵtemplate(3, PrivaExpanderComponent_Conditional_3_Template, 2, 1, "span", 2)(4, PrivaExpanderComponent_Conditional_4_Template, 1, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 3);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("is-expanded", ctx.isExpanded);
          i0.ɵɵproperty("ngClass", ctx.PrivaExpanderThemeClass[ctx.theme == null ? null : ctx.theme.toLowerCase()]);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("expander-trigger-soft", ctx.hasSoftTrigger);
          i0.ɵɵproperty("theme", ctx.theme)("iconState", ctx.triggerIconState);
          i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(2, 9, ctx.isExpanded ? "GLOBAL.ACTION.COLLAPSE" : "GLOBAL.ACTION.EXPAND"));
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.triggerTemplate ? 3 : 4);
        }
      },
      dependencies: [i1.NgClass, i1.NgTemplateOutlet, i2.PrivaButtonComponent, i3.PrivaTitleComponent, i4.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaExpanderComponent, [{
    type: Component,
    args: [{
      selector: 'priva-expander',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n    class=\"expander\"\n    [class.is-expanded]=\"isExpanded\"\n    [ngClass]=\"PrivaExpanderThemeClass[theme?.toLowerCase()]\"\n>\n    <button\n        data-test=\"expander-button\"\n        priva-button\n        type=\"button\"\n        (click)=\"triggerClick.emit($event)\"\n        class=\"expander-trigger\"\n        [class.expander-trigger-soft]=\"hasSoftTrigger\"\n        [theme]=\"theme\"\n        importance=\"none\"\n        [iconState]=\"triggerIconState\"\n        iconClass=\"expander-trigger-icon far fa-chevron-right\"\n        [attr.aria-label]=\"(isExpanded ? 'GLOBAL.ACTION.COLLAPSE' : 'GLOBAL.ACTION.EXPAND') | translate\"\n    >\n        @if (triggerTemplate) {\n            <span class=\"expander-trigger-content\">\n                <ng-container *ngTemplateOutlet=\"triggerTemplate\" />\n            </span>\n        } @else {\n            @if (triggerIconState.toLowerCase() !== 'only') {\n                <priva-title\n                    [size]=\"triggerSize\"\n                    [title]=\"!hasSoftTrigger ? triggerText : ''\"\n                    [theme]=\"theme\"\n                >\n                    @if (hasSoftTrigger) {\n                        <span data-title class=\"expander-trigger-label\">\n                            {{ triggerText }}\n                        </span>\n                    }\n                </priva-title>\n            }\n        }\n    </button>\n    <div class=\"expander-content\" data-test=\"expander-content\">\n        <ng-content />\n    </div>\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    triggerIconState: [{
      type: Input
    }],
    triggerText: [{
      type: Input
    }],
    triggerSize: [{
      type: Input
    }],
    triggerTemplate: [{
      type: Input
    }],
    isExpanded: [{
      type: Input
    }],
    hasSoftTrigger: [{
      type: Input
    }],
    triggerClick: [{
      type: Output
    }]
  });
})();
class PrivaExpanderModule {
  static {
    this.ɵfac = function PrivaExpanderModule_Factory(t) {
      return new (t || PrivaExpanderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaExpanderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaButtonModule, PrivaTitleModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaExpanderModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaExpanderComponent],
      imports: [CommonModule, PrivaButtonModule, PrivaTitleModule, PrivaLocalizationModule],
      exports: [PrivaExpanderComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaExpanderComponent, PrivaExpanderModule };
